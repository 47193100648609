import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { LiteYouTubeEmbed } from './YTEmbed'

export const ProductTour = ({
  showJumpLinks = true,
  title = 'See Omni in action',
}: {
  showJumpLinks?: boolean
  title?: string
}) => {
  const [playtime, setPlaytime] = useState(0)
  const { value, setTrue } = useBoolean()
  const moveVideoTime = (time: number) => {
    setPlaytime(time)
    setTrue()
  }

  return (
    <section id="video" className="wide">
      <h2 className="section-heading">{title}</h2>

      <article className="video-intro">
        <LiteYouTubeEmbed
          isEnabled={value}
          enable={setTrue}
          id="9GduXHYYGbU"
          title="Omni Analytics - Introduction video"
          playtime={playtime}
        />
        {showJumpLinks && (
          <aside>
            <ul>
              <li onClick={() => moveVideoTime(94)}>
                <h5>(1:34)</h5>
                <h3>Analyze with your preferred language</h3>
                <p>
                  Use the point & click UI, SQL, Excel, or AI to get answers
                  from your data.
                </p>
              </li>
              <li onClick={() => moveVideoTime(327)}>
                <h5>(5:27)</h5>
                <h3>Promote to the shared model</h3>
                <p>
                  Explore freely in your workbook, then build upon your team's
                  shared data model.
                </p>
              </li>
              <li onClick={() => moveVideoTime(390)}>
                <h5>(6:30)</h5>
                <h3>Move faster with dbt</h3>
                <p>
                  With Omni's dbt integration, you can pull in metadata, create
                  new models, and more.
                </p>
              </li>
            </ul>
          </aside>
        )}
      </article>
    </section>
  )
}
